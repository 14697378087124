'use client'
import './Dropup.scss'
import { isClientSide } from '@naturalcycles/js-lib'
import { useWindowScroll, useWindowSize } from '@uidotdev/usehooks'
import { clsx } from 'clsx'
import { usePathname } from 'next/navigation'
import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from '@/components/NavLink'
import { useClickOutside } from '@/hooks/useClickOutside'
import { useKeyDown } from '@/hooks/useKeyDown'

interface DropupContent {
  title: string
  links: MyLink[]
}

interface MyLink {
  url: string
  label: string
}

const casesContent: DropupContent = {
  title: 'portfolio',
  links: [
    { url: '/portfolio/editorial', label: 'editorial' },
    { url: '/portfolio/advertising', label: 'advertising' },
    { url: '/portfolio/e-commerce', label: 'e-commerce' },
  ],
}

const servicesContent: DropupContent = {
  title: 'services',
  links: [
    { url: '/services/post-production', label: 'post-production' },
    { url: '/services/production', label: 'production' },
    { url: '/services/studio-rental', label: 'studio rental' },
  ],
}

const showDropUpBelow = 200
const showDropUpAboveCases = 400
const showDropUpAboveServices = 1200

export function Dropup(): React.ReactElement | null {
  const myRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const [showDropUp, setShowDropUp] = useState(false)
  const [{ y }] = useWindowScroll()
  const { height: windowHeight } = useWindowSize()
  const bodyHeight = isClientSide() ? document.body.offsetHeight : 0 // todo: could be a hook
  const pathname = usePathname()
  const weAreInPortfolio = pathname.includes('/portfolio/')
  const weAreInServices = pathname.includes('/services/')
  // console.log({ weAreInCases, weAreInServices })

  const showDropUpAbove = weAreInPortfolio ? showDropUpAboveCases : showDropUpAboveServices

  useEffect(() => {
    // console.log({bodyHeight, windowHeight})
    const shouldBeShown =
      !!y &&
      !!windowHeight &&
      y >= showDropUpBelow &&
      y + windowHeight <= bodyHeight - showDropUpAbove
    // console.log({shouldBeShown})
    setShowDropUp(shouldBeShown)
    if (!shouldBeShown) {
      setShowMenu(false)
    }
  }, [y, bodyHeight, windowHeight, showDropUpAbove])

  useClickOutside(myRef, () => {
    setShowMenu(false)
  })

  useKeyDown('Escape', () => {
    setShowMenu(false)
  })

  let content: DropupContent | undefined

  if (weAreInPortfolio) {
    content = casesContent
  } else if (weAreInServices) {
    content = servicesContent
  } else {
    return null
  }

  function handleClick(): void {
    setShowMenu(!showMenu)
  }

  function handleNavClick(): void {
    setShowMenu(false)
  }

  return (
    <div className={`dropup ${showDropUp && 'dropup_opened'}`} ref={myRef}>
      <button
        className={clsx({
          'dropup__toggle-btn': true,
          'dropup__toggle-btn_opened': showMenu,
          'dropup__toggle-btn_full-opacity': showMenu,
        })}
        onClick={handleClick}
      >
        <div className="dropup__toggle-btn-arrow" />
        <p className="dropup__toggle-btn-title">{content.title}</p>
      </button>

      {showMenu && (
        <div className="dropup__menu">
          <ul className="dropup__menu-list">
            {content.links.map(link => (
              <NavLink
                href={link.url}
                key={link.url}
                scroll={true}
                onClick={handleNavClick}
                className="dropup__menu-link"
              >
                {link.label}
              </NavLink>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
