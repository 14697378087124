'use client'
import { AdminService } from '@naturalcycles/js-lib'

export const adminService = new AdminService({
  onChange: adminMode => console.log({ adminMode }),
  onRedDotClick: () => alert('RedDot clicked'),
})

// adminService.adminMode = true
adminService.startListening()
